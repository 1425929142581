import React from "react";
import Helmet from "react-helmet";
import { SocialIcon } from "react-social-icons";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./layout.module.css";

export const titleAndAlt = string => ({ alt: string, title: string });

export const ScheduleButton = ({ calendly, social }) => (
  <a
    className={styles.schedule}
    href={`https://calendly.com/${social}/${calendly}`}
    rel="noopener noreferrer"
    target="_blank"
    {...titleAndAlt("Schedule an appointment")}
  >
    Schedule Appointment
  </a>
);

const Header = ({ data }) => {
  const { calendly, email, social } = data.site.siteMetadata;
  return (
    <header>
      <h1>
        <a href="/">
          <StaticImage
            className="headerImg"
            src="../images/logo_green_transparentBG.png"
            alt="logo"
          />
        </a>
      </h1>
      <div className={styles.contact}>
        <ul>
          <li>
            <ScheduleButton social={social} calendly={calendly} />
          </li>
          <li>
            <a
              href="tel:+16513330914"
              {...titleAndAlt("Erin Go Dog phone number")}
            >
              (651) 333-0914
            </a>
          </li>
          <li>
            <SocialIcon
              url={`mailto:${email}`}
              bgColor="#ffffff"
              fgColor="#126937"
            />
          </li>
          <li>
            <SocialIcon
              url={`https://facebook.com/${social}`}
              bgColor="#ffffff"
              fgColor="#126937"
            />
          </li>
          <li>
            <SocialIcon
              url={`https://twitter.com/${social}`}
              bgColor="#ffffff"
              fgColor="#126937"
            />
          </li>
          <li>
            <SocialIcon
              url={`https://instagram.com/${social}`}
              bgColor="#ffffff"
              fgColor="#126937"
            />
          </li>
        </ul>
      </div>
    </header>
  );
};

const Footer = () => {
  return (
    <footer>
      <p>
        &copy; 2017 ErinGoDog.{" "}
        <a
          href="https://goo.gl/forms/mY21cpvyAx3oNAhr2"
          {...titleAndAlt("Site feedback")}
        >
          Site feedback
        </a>
      </p>
    </footer>
  );
};

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            calendly
            description
            email
            keywords
            social
          }
        }
      }
    `
  );

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{data.site.siteMetadata.title}</title>
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta
          property="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta property="og:type" content="website" />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <Link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <Header data={data} />
      {children}
      <Footer />
    </div>
  );
}
